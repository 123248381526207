import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import ImgCallum from "../../static/images/callum.png"
import ImgOscar from "../../static/images/oscar.png"
import ImgAisha from "../../static/images/aisha.png"
import ImgRob from "../../static/images/rob.png"
import ImgJo from "../../static/images/jo.png"
import ImgLesson from "../../static/images/lesson.png"
import ImgQuiz from "../../static/images/quiz.png"
import YellowDots from "../../static/images/yellow-dots.svg"
import PinkDots from "../../static/images/pink-dots.svg"
import BlueLine from "../../static/images/blue-line.svg"
import StoriesDots from "../../static/images/stories-dots.svg"
import PinkDot from "../../static/images/large-pink-dot.svg"
import BlueDots from "../../static/images/blue-dots.svg"
import { AnimateOnEntry } from "../components/animatedImageOnScroll"
import { ImageScroller } from "../components/imageScroller"

const Home = props => (
  <Layout>
    <Helmet>
      <title>IBDmate - Supporting you on your IBD journey</title>
    </Helmet>
    <div className="layout-index">
      <section className="white-bg" id="intro">
        <div className="wrapper">
          <div className="content">
            <h1>Supporting you on your IBD journey</h1>
            <p>IBDmate is an online resource for young people with inflammatory bowel disease (IBD) and their families to learn about Crohn’s disease, ulcerative colitis and IBDU currently being used by Addenbrooke's hospital UK.</p>
          </div>
          <ImageScroller />
        </div>
      </section>
      <section
        className="blue-bg"
        style={{ backgroundImage: `url(${BlueDots}),url(${PinkDot})` }}
        id="features"
      >
        <div className="wrapper">
          <div className="content">
            <h2>Made for you</h2>
            <p>All the videos, articles, quizzes, tips and advice have been made specifically for young people and families, with all medical jargon explained.</p>
            <p>Take courses about subjects relevant to your treatment, or dip in and out of things that interest you.</p>
          </div>
          <div className="screens">
            <AnimateOnEntry>
              <div className="screen-1">
                <img src={ImgLesson} alt="lesson" />
              </div>
              <div className="screen-2">
                <img src={ImgQuiz} alt="quiz" />
              </div>
            </AnimateOnEntry>
          </div>
        </div>
        <div className="youtube"></div>
      </section>
      <section className="white-bg" id="trusted">
        <div className="content">
          <h2>Trusted information</h2>
          <p>More than 15 paediatric <Link to="/experts">IBD experts</Link> share their knowledge on everything you need to know - from symptoms and diagnosis to tests, treatments and the future.</p>
          <p>IBDmate has been developed and tested with the paediatric IBD team at Addenbrooke’s Hospital in Cambridge.</p>
          <p>
            <Link to="/explore/">Learn more</Link>
          </p>
        </div>
        <div
          className="images"
          style={{ backgroundImage: `url(${YellowDots})` }}
        >
          <Link to="/experts/" className="image"><img src={ImgJo} alt="Jo" /></Link>
          <Link to="/experts/" className="image"><img src={ImgRob} alt="Rob" /></Link>
          <Link to="/experts/" className="btn btn-purple">See our experts</Link>
        </div>
      </section>
      <section
        className="blue-bg"
        id="stories"
        style={{ backgroundImage: `url(${BlueLine}),url(${StoriesDots})` }}
      >
        <div className="wrapper">
          <div className="story-content">
            <h2>Hear from patients</h2>
            <p>Young patients share their perspectives and experiences of life with IBD, the treatments and procedures they’ve faced and tips they’ve picked up along the way.</p>
          </div>
          <div className="story-images">
            <img src={ImgAisha} alt="Aisha" />
            <img src={ImgOscar} alt="Oscar" />
            <img src={ImgCallum} alt="Callum" />
            <Link to="/patients/" className="btn btn-purple">See our patients</Link>
          </div>
        </div>
      </section>
      <section
        className="white-bg"
        id="quotes"
        style={{ backgroundImage: `url(${PinkDots})` }}
      >
        <div className="quotes">
          {props.data.reviews.review.map(content => (
            <div className="quote">
              <div className="content" dangerouslySetInnerHTML={{ __html: content.frontmatter.review }} />
              <div className="author">
                <strong>{content.frontmatter.name}</strong>
                <br />{content.frontmatter.title}
              </div>
            </div>
          ))}
        </div>
        <Link to="/reviews/" className="btn btn-purple">See what people say</Link>
      </section>
    </div>
  </Layout>
)

export default Home

export const pageQuery = graphql`
  query {
    reviews: allMarkdownRemark(
      limit: 3
      filter:{
        fileAbsolutePath:{regex:"/(reviews)/"}
      }
      sort:{ order:ASC, fields: [frontmatter___sort] }
    ){
      review: nodes {
        frontmatter {
          name
          title,
          review
        }
      }
    }
  }
`
